import { EnergyType, GearboxType } from '../../../../types/sales';
import { Fields } from './types';

export const ANY_OPTION = {
  value: '',
  label: 'Любая',
  maleLabel: 'Любой', // NOTE: lifehack
};

export const LOTS_LIMIT_ALL_VALUE = 'All';

export const LOTS_LIMIT_ALL_OPTION = {
  value: LOTS_LIMIT_ALL_VALUE,
  label: 'Все',
};

export const GEARBOX_TYPE_TRANSLATIONS: Record<GearboxType, string> = {
  [GearboxType.Automatic]: 'Автоматическая',
  [GearboxType.Manual]: 'Механическая',
};

export const ENERGY_TYPE_TRANSLATIONS: Record<EnergyType, string> = {
  [EnergyType.Benzin]: 'Бензин',
  [EnergyType.Diesel]: 'Дизель',
  [EnergyType.Electric]: 'Электро',
  [EnergyType.Gaz]: 'Газ',
  [EnergyType.Hybride]: 'Гибрид',
};

export const LOT_LIMIT_OPTIONS = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
];

export const SOLD_DAYS_AGO_OPTIONS = [
  {
    value: 10,
    label: '10 дней',
  },
  {
    value: 30,
    label: '30 дней',
  },
  {
    value: 60,
    label: '60 дней',
  },
  {
    value: 90,
    label: '90 дней',
  },
  {
    value: 180,
    label: '180 дней',
  },
  {
    value: 365,
    label: '365 дней',
  },
];

export const FIELDS_TO_LABELS: Record<Fields, string> = {
  [Fields.CarName]: 'Название авто',
  [Fields.CarModel]: 'Комплектация авто',
  [Fields.EnergyType]: 'Вид топлива',
  [Fields.GearboxType]: 'Коробка передач',
  [Fields.MinRegistrationYear]: 'Год от',
  [Fields.MaxRegistrationYear]: 'до',
  [Fields.MinMileage]: 'Пробег от',
  [Fields.MaxMileage]: 'до',
  [Fields.SaleId]: 'Аукцион',
  [Fields.LotNumber]: 'Номер лота',
  [Fields.ExcludeHS]: 'Исключить Multisite - HS - поломанные авто',
  [Fields.MaxSoldDaysAgo]: 'Cредняя цена за',
  [Fields.LotsLimit]: 'Сколько авто показать',
  [Fields.OnlySold]: 'Учитывать только проданные',
};

export const BROKEN_CARS_SALE = 'multisite';
